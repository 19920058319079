import React, { memo, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, TextField, Typography } from "@mui/material";
import AddApplication from "../Applications/ApplicationDetails/Transactions/AddApplication.js";
import apiRequest from "../../../utils/api.js";
import moment from "moment";
import CommonTable from "../../common/CommonTable.js";
import { useNavigate } from "react-router-dom";
import FiltersDrawer from "../../common/FiltersDrawer.js";
import AppplicationListFilters from "./AppplicationListFilters.js";
import CommonButton from "../../common/CommonButton.js";
import { useSelector } from "react-redux";
import CommonPagination from "../../common/CommonPagination.js";
import { onDownloadClick } from "../../../utils/common.js";
import { checkViewPermission } from "../../../utils/common.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ApplicationList = () => {
  const isViewPermissionValid = checkViewPermission();
  const [openAddApplication, setOpenAddApplication] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({});
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const openAddApplicationModal = () => {
    setOpenAddApplication(!openAddApplication);
  };

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  const fetchTransactionListData = useCallback(
    async () => {
      const accessToken = localStorage.getItem("token");

      const res = await apiRequest("/list-application", "POST", {
        token: accessToken,
        page: page,
        searchText: debouncedSearch,
        orderBy: sortConfig?.orderBy,
        sortBy: sortConfig?.sortBy,
        ...filters,
      });
      if (res.success) {
        setTransactionData(res.data.applicationsList);
        setTotalPages(res?.data?.totalCount);
      }
    }, [page, debouncedSearch, filters, sortConfig]
  );


  const tableHeaders = [
    { headerItem: "Application ID" },
    { headerItem: "Name", sortValue: "name" },
    { headerItem: "App-Id" },
    { headerItem: "Email" },
    { headerItem: "Created On", sortValue: "createdDate" },
    { headerItem: "Created By" }
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell component="th" scope="row">
          {row?.applicationId ? row.applicationId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.name ? row.name : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.appId ? row.appId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.email ? row.email : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(row.createdDate).format("DD/MM/YYYY") || "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.createdBy ? row.createdBy : "NA"}
        </StyledTableCell>
      </>
    );
  };

  const handleRowClick = (row) => {
    navigate(`/applications/${row._id}`);
  };

  useEffect(() => {
    fetchTransactionListData();
  },
    [page, fetchTransactionListData]
  );

  const handleSorting = (orderBy, sortBy) => {
    setSortConfig({ orderBy, sortBy });
  };

  return (
    <Box className="rounded-lg w-full application-list h-[93%]">
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
            Application List
          </Typography>
        </div>
        <div className="flex gap-2 mr-4">
          <TextField
            className="w-96"
            placeholder="Search Applications"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <img
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                  alt="search"
                  className="w-6 h-6"
                />
              ),
            }}
          />
          <CommonButton
            text="Add Aplications"
            bgColor="black"
            onClick={isViewPermissionValid ? undefined: openAddApplicationModal}
            disabled={isViewPermissionValid}
            StartIcon={
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/plus_button.png`}
                alt="add-icon"
                className="w-6 h-6"
              />
            }
          />
          <Box onClick={isViewPermissionValid? undefined: () => onDownloadClick(transactionData, "Applications-List-Export")} className="cursor-pointer">
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/download_button.png`}
              alt="download-icon"
              className="w-10 h-10"
            />
          </Box>
          <CommonButton
            text="Filter"
            bgColor="white"
            onClick={openDrawer}
            StartIcon={
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                alt="filter"
                className="w-6 h-6"
              />
            }
          />
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/settings_button.png`}
            alt="settings"
            className="w-10 h-10"
          />
        </div>
      </div>
      <div className="overflow-x-auto h-[70%]">
        <CommonTable
          headers={tableHeaders}
          data={transactionData}
          renderRow={renderRow}
          handleRowClick={handleRowClick}
          sorting={handleSorting}
          setSortConfig={setSortConfig}
          sortConfig={sortConfig}
        />
        <AddApplication
          open={openAddApplication}
          close={openAddApplicationModal}
          fetchTransactionListData={fetchTransactionListData}
        />
      </div>
      <div className="mt-auto border-t-[1px]">
        <CommonPagination totalPages={totalPages} setPage={setPage} page={page} />
      </div>
      <FiltersDrawer open={isDrawerOpen} onClose={closeDrawer}>
        <AppplicationListFilters
          setPage={setPage}
          setFilters={setFilters}
          closeDrawer={closeDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default memo(ApplicationList);
