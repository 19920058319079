import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, IconButton } from "@mui/material";
import CommonTable from "../common/CommonTable.js";
import CommonButton from "../common/CommonButton.js";
import { useSelector } from "react-redux";
import CommonPagination from "../common/CommonPagination.js";
import AddConfiguration from "./AddConfiguration.js";
import FiltersDrawer from "../common/FiltersDrawer.js";
import SweepConfigurationFilter from "./SweepConfigurationFilter.js";
import SweepsSetting from "./SweepsSetting.js";
import apiRequest from "../../utils/api.js";
import UpdateConfiguration from "./UpdateConfiguration.js";
import { checkViewPermission } from "../../utils/common.js";

const SweepsConfiguration = () => {
    const isViewPermissionValid = checkViewPermission()
    const [openAddConfiguration, setOpenAddConfiguration] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSweepsSetting, setIsSweepsSetting] = useState(false);
    const [sweepConfigList, setSweepConfigList] = useState([]);
    const [cryptoCurrencyList, setCryptoCurrencyList] = useState([{_id:"", symbol:'Select Crypto'}]);
    const [paymentProviderUpdateItem, setPaymentProviderUpdateItem] = useState({});
    const [inputFields, setInputFields] = useState({
        paymentProvider: "",
        crypto: [],
        minFullWalletsThresholds: "",
        minUSDThresholds: "",
        maxUSDThresholds: "",
        takeAllFunds: "",
        archive: "",
        maxFeeThresholds: "",
        maxPercentThresholds: "",
        sweepFlag: "",
        configFlag: "",
    });
    const [reload, setReload] = useState(false);
    const [filters, setFilters] = useState({});

    const [page, setPage] = useState(1);
    const [totalCount, setTotalCont] = useState(1);

    const filterHandleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === 'crypto') {
            if (value.length && value[value.length - 1] === 'All') {
                value = ['All']
            } else if (value.includes('All')) {
                value = value.filter(item => item !== 'All')
            }
        }
        setInputFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const filterOnSubmit = async () => {
        setPage(1);
        let crypto = inputFields.crypto
        if (inputFields.crypto.includes('All')) {
            crypto = cryptoCurrencyList.map((item) => item._id);
        }
        const filters = {
            ...inputFields,
            crypto,
        }
        setFilters(filters);
        handleDrawer();
    };
    const filterRest = () => {
        setFilters({});
        setPage(1);
        setInputFields({
            paymentProvider: "All",
            crypto: [],
            minFullWalletsThresholds: "",
            minUSDThresholds: "",
            maxUSDThresholds: "",
            takeAllFunds: "All",
            archive: "All",
            maxFeeThresholds: "",
            maxPercentThresholds: "",
            sweepFlag: "All",
            configFlag: "All",
        });
    }

    const openSweepsSetting = () => {
        setIsSweepsSetting(!isSweepsSetting);
    }

    const handleDrawer = () => {
        setIsDrawerOpen(prev => !prev);
    };

    const openAddConfigurationModal = useCallback(() => {
        setOpenAddConfiguration(!openAddConfiguration);
    }, [openAddConfiguration])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F4F4F5",
            color: "#51525C",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

    useEffect(() => {
        (async () => {
            try {
                if (!page) return;
                let body = {}
                Object.keys(filters || {})?.forEach(item => {
                    if (filters?.[item] !== 'All' && filters?.[item] !== '') { // 
                        body[item] = filters?.[item]; // condition satisfy for the case - filters?.[item] = false
                    }
                });
                const res = await apiRequest("/get-sweep-config", "POST", {
                    page: page,
                    ...body
                });
                if (!res?.success) throw new Error(JSON.stringify(res?.error));
                const result = res?.data?.sweepConfigList;
                setTotalCont(result?.totalCount || 0);
                setSweepConfigList((result?.paymentProviderSweepConfig || [])?.map(item => ({
                    id: item?._id,
                    crypto: item?.crypto?.symbol,
                    cryptoId: item?.crypto?._id,
                    paymentProviderId: item?.paymentProvider,
                    minFullWalletThresholds: item?.sweepAmountThresholds?.minFullWallets,
                    minUsdThresholds: item?.sweepAmountThresholds?.minAmountUsd,
                    maxUsdThresholds: item?.sweepAmountThresholds?.maxAmountUsd,
                    takeAllFunds: item?.sweepAmountThresholds?.takeAllFunds,
                    archive: item?.sweepAmountThresholds?.archive,
                    maxFeeThresholds: item?.feeThresholds?.maxAmount,
                    maxPercentageThresholds: `${item?.feeThresholds?.maxPercent}%`,
                    sweepFlag: item?.sweepFlag,
                    configFlag: item?.configFlag
                })))
            } catch (error) {
                console.error("Error: " + error);
            }

        })()
    }, [page, reload, filters]);
    const cryptoListGet = useCallback(async () => {
        try {
            const res = await apiRequest("/list-crypto", "POST", {});
            if (!res?.success) throw new Error(JSON.stringify(res?.error));
            const result = res?.data?.CryptoSettingList || [];
            setCryptoCurrencyList(prev => [...prev, ...result]);
        } catch (error) {
            console.error("Error: ");
        }
    }, []);
    useEffect(() => {
        cryptoListGet();
    }, [cryptoListGet]);

    const updatePaymentProviderConfigCloseHandler = useCallback(() => setPaymentProviderUpdateItem({}), []);
    const updateDialogToggle = useMemo(() => Boolean(Object.keys(paymentProviderUpdateItem || {}).length), [paymentProviderUpdateItem])


    const tableHeaders = [
        { headerItem: "Crypto" },
        { headerItem: "Min Full Wallets Thresholds" },
        { headerItem: "Min USD Thresholds" },
        { headerItem: "Max USD Thresholds" },
        { headerItem: "Take All Funds" },
        { headerItem: "Archive" },
        { headerItem: "Max Fee Thresholds" },
        { headerItem: "Max Percent Thresholds" },
        { headerItem: "Sweep Flag" },
        { headerItem: "Config Flag" },
        { headerItem: "Action" },
    ];

    const renderRow = (row) => {
        return (
            <>
                <StyledTableCell align="left">
                    {row?.crypto ? row?.crypto : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.minFullWalletThresholds === 'number' ? row?.minFullWalletThresholds : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.minUsdThresholds === 'number' ? row?.minUsdThresholds : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.maxUsdThresholds === 'number' ? row?.maxUsdThresholds : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {/* {JSON.stringify(typeof row?.takeAllFunds)} */}
                    {typeof row?.takeAllFunds === 'boolean' ? row?.takeAllFunds.toString() : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.archive === 'boolean' ? row?.archive.toString() : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.maxFeeThresholds === 'number' ? row?.maxFeeThresholds : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {row?.maxPercentageThresholds ? row?.maxPercentageThresholds : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.sweepFlag === 'boolean' ? row?.sweepFlag.toString() : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    {typeof row?.configFlag === 'boolean' ? row?.configFlag.toString() : "NA"}
                </StyledTableCell>
                <StyledTableCell align="left">
                    <div>
                        <IconButton
                            onClick={isViewPermissionValid? undefined: () => {
                                setPaymentProviderUpdateItem(row)
                            }}
                        >
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
                                alt="edit-icon"
                                className="w-5 h-5"
                            />
                        </IconButton>
                    </div>
                </StyledTableCell>
            </>
        );
    };

    return (
        <Box className='h-full'>
            <div className="flex justify-between items-center relative">
                <p className="font-semibold p-6">
                    Sweep Configuration
                </p>
                <div className="flex gap-2 mr-4">
                    <CommonButton
                        text="Add Configuration"
                        bgColor="black"
                        onClick={isViewPermissionValid? undefined: openAddConfigurationModal}
                        disabled={isViewPermissionValid}
                        StartIcon={
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/plus_button.png`}
                                alt="add-icon"
                                className="w-6 h-6"
                            />
                        }
                    />
                    {AddConfiguration && (<AddConfiguration
                        open={openAddConfiguration}
                        cryptoCurrencyList={cryptoCurrencyList}
                        setReload={setReload}
                        close={openAddConfigurationModal} />)}
                    {UpdateConfiguration && (<UpdateConfiguration
                        open={updateDialogToggle}
                        cryptoCurrencyList={cryptoCurrencyList} // changable
                        paymentProviderUpdateItem={paymentProviderUpdateItem}
                        setReload={setReload} // changable
                        close={updatePaymentProviderConfigCloseHandler} />)}
                    <CommonButton
                        text="Filters"
                        bgColor={Object.keys(filters || {}).length ? 'black' : 'white'}
                        onClick={handleDrawer}
                        StartIcon={
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                                alt="filter"
                                className="w-6 h-6"
                            />
                        }
                    />
                    {isSweepsSetting && (
                        <SweepsSetting close={openSweepsSetting} />
                    )}
                </div>
            </div>
            <div className="flex flex-col h-[74vh]">
                <div className="overflow-x-auto">
                    <CommonTable
                        headers={tableHeaders}
                        renderRow={renderRow}
                        data={sweepConfigList}
                    />
                </div>
                <div className="border-t-[1px] p-0 m-0 mt-auto">
                    <CommonPagination totalPages={totalCount} setPage={setPage} page={page} />
                </div>
            </div>
            <FiltersDrawer open={isDrawerOpen} onClose={handleDrawer}>
                <SweepConfigurationFilter
                    cryptoCurrencyList={cryptoCurrencyList}
                    handleInputChange={filterHandleInputChange}
                    onSubmit={filterOnSubmit}
                    inputFields={inputFields}
                    reset={filterRest}
                // closeDrawer={handleDrawer}
                />
            </FiltersDrawer>
        </Box>
    )
}
export default SweepsConfiguration;