import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import CommonTable from "../common/CommonTable.js";
import { useParams, Link as RouterLink } from "react-router-dom";
import CopyButton from "../common/CopyButton.js";
import apiRequest from "../../utils/api.js";
import DropDown from "../common/DropDown.js";
import moment from "moment";
import "../Wallets/Wallet.css";
import EditBalance from "../common/CommonEditBalance.js";
import CommonButton from "../common/CommonButton.js";
import { getCryptoImage, onDownloadClick } from "../../utils/common.js";
import { useDispatch, useSelector } from "react-redux";
import CommonPagination from "../common/CommonPagination.js";
import { getCryptoLink } from "../../utils/common.js";
import { checkViewPermission } from "../../utils/common.js";
import TransferWalletBalance from "../CentralisedWallets/TransferWalletBalance.js";
import { setTransferWalletAddressList } from "../../redux/actions/commonAction.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const WalletDetails = () => {
  const isViewPermissionValid = checkViewPermission();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [walletData, setWalletData] = useState({
    balance: [],
    balanceHistory: [],
    balanceAmountUSDApprox: [],
    walletDetailObj: {},
  });
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [openEditBalance, setOpenEditBalance] = useState(false);
  const [isTransferModelOpen, setIsTransferModelOpen] = useState(false);
  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);
  const [balanceData, setBalanceData] = useState({});
  const [sortConfig, setSortConfig] = useState({});
  const [toastMessage, setToastMessage] = useState("");

  const dispatch = useDispatch();
  const walletAddressList = useSelector((state) => state?.common?.walletAddress?.walletAddress)

  const handleToggleTransferModal = () => {
    if (!isTransferModelOpen) {
      fetchTransferWallets();
    }
    setIsTransferModelOpen((prev) => !prev);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") return;
    setToastMessage("");
  }

  const openEditBalanceModal = (balanceData) => {
    setBalanceData(balanceData);
    setOpenEditBalance(!openEditBalance);
    fetchWalletDetailsData();
  };

  const params = useParams();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to="/"
    >
      <img
        src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_dashboard.png`}
        alt="dashboardIcon"
        className="w-6 h-6"
      />
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      component={RouterLink}
      to="/wallets"
      className="flex gap-2"
    >
      <img
        src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_wallet.png`}
        alt="dashboardIcon"
        className="w-6 h-6"
      />
      <span className="mr-4">Wallets</span>
    </Link>,
    <Typography key="3" color="text.primary">
      <span className="ml-3 text-[#18181B]">
        Wallet Address: {params?.addressKey}
        <CopyButton textToCopy={params?.addressKey} />
      </span>
    </Typography>,
  ];

  const handleChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  const fetchWalletDetailsData = useCallback(
    async () => {
      const res = await apiRequest("/get-wallet-details", "POST", {
        addressKey: params?.addressKey,
        page: page,
        searchText: debouncedSearch,
        orderBy: sortConfig?.orderBy,
        sortBy: sortConfig?.sortBy,
      });
      if (res?.success) {
        setWalletData({
          balance: res?.data?.wallet?.balance,
          balanceHistory: res?.data?.wallet?.balanceHistory,
          balanceAmountUSDApprox:
            res?.data?.wallet?.balanceAmountUSDApprox,
          walletDetailObj: res?.data?.wallet
        });
        setTotalPages(res?.data?.totalCount);
      }
    },
    [page, params?.addressKey, debouncedSearch, sortConfig]
  );

  const fetchTransferWallets = useCallback(async () => {
    try {
      const blockchain = [].concat(walletData?.walletDetailObj?.blockchain || []);
      const network = [].concat(walletData?.walletDetailObj?.network || []);
      if (!blockchain || !network) {
        console.error("Blockchain or network is missing");
        return;
      }
      const body = { blockchain, network };
      const res = await apiRequest("/get-centralised-wallets", "POST", body);
      if (!res?.success) {
        console.error("API request failed:", res?.error);
        return;
      }
      const result = (res?.data?.centralisedWalletList || []).map(item => ({
        id: item?.id,
        value: item?.address?.key,
        label: item?.address?.key,
      }));
      dispatch(setTransferWalletAddressList(result))
    } catch (error) {
      console.error("Error fetching transfer wallets: ", error);
      setToastMessage(`Error fetching  transfer wallets: ${error.message}`);
    }
  }, [walletData?.walletDetailObj?.blockchain, walletData?.walletDetailObj?.network, dispatch]
  );

  const tableHeaders = [
    { headerItem: "Date", sortValue: "updatedDate" },
    { headerItem: "Transaction Type" },
    { headerItem: "Transaction ID" },
    { headerItem: "Asset" },
    { headerItem: "Amount", sortValue: "amount" },
    { headerItem: "Previous Balance" },
    { headerItem: "Current Balance" },
  ];

  const tableWalletInfoHeaders = [
    { headerItem: "Token" },
    { headerItem: "Balance Amount" },
    { headerItem: "Last Updated" },
    { headerItem: "" },
  ];

  const renderWalletInfoRow = (row) => {
    return (
      <>
        <StyledTableCell>
          <Box className="flex items-center gap-2">
            {getCryptoImage(row?.cryptoSymbol, imageBaseURL, 6)}
            {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box className="flex gap-4">
            {row?.balanceAmount ? row?.balanceAmount?.toFixed(6) : "0.0"}
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
              alt="edit-icon"
              onClick={isViewPermissionValid ? undefined : () => openEditBalanceModal(row)}
              className="w-5 h-5"
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.updatedDate
            ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
            : "NA"}
        </StyledTableCell>
      </>
    );
  };

  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell align="left">
          {row?.updatedDate
            ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.transactionType ? row?.transactionType : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.transactionId ? row?.transactionId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.amount ? row?.amount?.toFixed(6) : "0.0"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.previousBalance ? row?.previousBalance?.toFixed(6) : "0.0"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.currentBalance ? row?.currentBalance?.toFixed(6) : "0.0"}
        </StyledTableCell>
      </>
    );
  };

  const handleSorting = (orderBy, sortBy) => {
    setSortConfig({ orderBy, sortBy });
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  useEffect(
    (debouncedSearch) => {
      if (debouncedSearch) {
        fetchWalletDetailsData();
      } else {
        fetchWalletDetailsData();
      }
    },
    [page, fetchWalletDetailsData, debouncedSearch]
  );
  const navigateToBlockchain = () => {
    let url = getCryptoLink(
      walletData?.walletDetailObj?.network,
      walletData?.walletDetailObj?.crypto?.symbol,
      walletData?.walletDetailObj?.address?.key,
    );
    url === undefined && walletData?.walletDetailObj?.blockchain ? setToastMessage("Litecoin will work only for mainnet wallets") : window.open(url, "_blank");
  }

  return (
    <Box className="flex flex-col mb-4 gap-4">
      {isTransferModelOpen &&
        <TransferWalletBalance
          close={handleToggleTransferModal}
          open={isTransferModelOpen}
          blockchain={walletData?.walletDetailObj?.blockchain}
          network={walletData?.walletDetailObj?.network}
          cryptoSymbol={walletData?.walletDetailObj?.crypto?.symbol}
          fromWalletAddress={walletData?.walletDetailObj?.address?.key}
          applicationId={walletData?.walletDetailObj?.application?._id}
          transfersWalletList={walletAddressList}
        />}
      <Box>
        <Breadcrumbs
          separator={
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_next.png`}
              alt="dashboardIcon"
              className="w-[18px] h-[18px]"
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box className="border-[1px] rounded-lg w-full wallet-details">
        <div className="flex justify-between p-6">
          <div>
            <EditBalance
              open={openEditBalance}
              close={openEditBalanceModal}
              currentBalance={balanceData?.balanceAmount}
              cryptoSymbol={balanceData?.cryptoSymbol}
              walletId={walletData?.walletDetailObj?._id}
              newBalanceAmountUSDApprox={walletData?.balanceAmountUSDApprox}
              fetchWalletDetailsData={fetchWalletDetailsData}
              blockchain={walletData?.walletDetailObj?.blockchain}
              network={walletData?.walletDetailObj?.network}
              application={walletData?.walletDetailObj?.application}
              addressKey={params?.addressKey}
              path="/update-wallet-crypto-balance"
            />
            <div className="font-semibold">
              Ethereum wallet{" "}
              <DropDown options={["Full", "Available", "In Use", "Locked"]} />
            </div>
            {params?.addressKey} <CopyButton textToCopy={params?.addressKey} />
          </div>
          <div className="flex gap-2 items-center">
            <CommonButton text="Transfer" bgColor={"black"} onClick={isViewPermissionValid ? undefined : handleToggleTransferModal} disabled={isViewPermissionValid} />
            <CommonButton text="View on blockchain" bgColor="black" onClick={isViewPermissionValid ? undefined : navigateToBlockchain} disabled={isViewPermissionValid} />
          </div>
        </div>
        <Box>
          <CommonTable
            headers={tableWalletInfoHeaders}
            data={walletData?.balance}
            renderRow={renderWalletInfoRow}
            approxUSDBalance={walletData?.balanceAmountUSDApprox}
          />
        </Box>
        <Box className="flex  flex-col justify-between p-6">
          <div>
            <p className="font-semibold ">All Transactions</p>
          </div>
          <div className="flex justify-between">
            <div className="flex py-6">
              <TextField
                placeholder="Search transactions"
                data-e2e="search-transaction"
                type="search"
                size="small"
                onChange={handleChangeSearchText}
                value={searchText}
                InputProps={{
                  endAdornment: (
                    <img
                      src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                      alt="search"
                      className="w-6 h-6"
                    />
                  ),
                }}
              />
            </div>
            <div className="items-center flex justify-center gap-2">
              <Box onClick={isViewPermissionValid? undefined: () => onDownloadClick(walletData.balanceHistory,"Wallet-History-Export")}  className="cursor-pointer" >
                <img
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/download_button.png`}
                  alt="download-icon"
                  className="w-10 h-10"
                />
              </Box>
              <CommonButton
                text="Filter"
                bgColor="white"
                StartIcon={
                  <img
                    src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                    alt="filter"
                    className="w-6 h-6"
                  />
                }
              />
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/settings_button.png`}
                alt="settings"
                className="w-10 h-10"
              />
            </div>
          </div>
        </Box>
        <Box>
          <CommonTable
            headers={tableHeaders}
            data={walletData?.balanceHistory}
            renderRow={renderRow}
            sorting={handleSorting}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
          />
          <Snackbar
            open={Boolean(toastMessage)}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            message={toastMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </Box>
        <div className="mt-2 flex justify-between p-6 w-full">
          <CommonPagination
            totalPages={totalPages}
            setPage={setPage}
            page={page}
          />
        </div>
      </Box>
    </Box>
  );
};
export default WalletDetails;
