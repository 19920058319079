import React, { useState, useEffect } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import constant from "../../../../../utils/constant.js";
import CommonTable from "../../../CommonTable.js";
import { useSelector, useDispatch } from "react-redux";
import { getwebhookUrlType } from "../../../../../utils/common.js";
import CopyButton from "../../../../common/CopyButton.js";
import useDebounce from "../../../../../customHook/useDebounce.js";
import apiRequest from "../../../../../utils/api.js";
import { setSingleApplication } from "../../../../../redux/actions/globalSettingsAction.js";
import { checkViewPermission } from "../../../../../utils/common.js";

const Webhooks = () => {
  const isViewPermissionValid = checkViewPermission()
  const [transactionType, setTransactionType] = useState("Buy");
  const [tableData, setTableData] = useState([{ url: "" }]);
  const [openToast, setOpenToast] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const headers = [{ label: "Api Endpoints" }, { label: "" }];
  const { transactionCategories } = constant;

  const dispatch = useDispatch();
  const application = useSelector(
    (state) => state.globalSettings.application?.data
  );

  useEffect(() => {
    if (application?.notificationURL) {
      const webhookUrltype = getwebhookUrlType(transactionType);
      setTableData([{ url: application?.notificationURL?.[webhookUrltype] }]);
    } else {
      setTableData([{ url: "" }]);
    }
  }, [transactionType, application]);

  const updateUrl = async (newUrl) => {
    const webhookUrltype = getwebhookUrlType(transactionType);
    try {
      const res = await apiRequest("/update-application-data", "POST", {
        applicationId: application?._id,
        notificationURL: {
          [webhookUrltype]: newUrl,
        },
      });

      if (res?.success) {
        dispatch(setSingleApplication(res?.data));
        setOpenToast("Url updated sucessfully");
      } else {
        setOpenToast(res?.error);
      }
    } catch (err) {
      console.error(err);
      setOpenToast(err);
    }
  };

  const debouncedUpdateUrl = useDebounce((url) => {
    updateUrl(url);
  }, 500);

  const handleEndpointChange = (e) => {
    setTableData([{ url: e.target.value }]);
    debouncedUpdateUrl(e.target.value);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const handleToggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const renderRow = (row, index) => {
    return (
      <>
        <TableCell component="th" scope="row" className="w-60">
          <Box className="flex items-center gap-4">
            <p className="text-gray-900 w-80">Dev Environment: </p>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="flex gap-2">
            <input
              type={isVisible ? "text" : "password"}
              className="w-2/5 border-[1px] p-[10px] pl-[14px] rounded-lg"
              value={row?.url || ""}
              onChange={isViewPermissionValid? undefined: (e) => handleEndpointChange(e)}
              disabled={isViewPermissionValid}
              placeholder="Enter URL"
            />
            <Box
              className="border-[1px] rounded-lg p-2"
              onClick={handleToggleVisibility}
            >
              {isVisible ? (
                <img src= {`${imageBaseURL}/images/caas-admin-ui/icons/eye-on.svg`} className="h-5" alt="eye-on" />
              ) : (
                <img src= {`${imageBaseURL}/images/caas-admin-ui/icons/eye-off.png`} alt="eye-off" />
              )}
            </Box>
            <Box className="border-[1px] rounded-lg">
              <CopyButton textToCopy={row?.url} />
            </Box>
          </Box>
        </TableCell>
      </>
    );
  };

  return (
    <Box>
      <Box className="flex gap-[8px] ml-6 mb-6">
        {transactionCategories.map((item) => (
          <Box
            key={item}
            className={`p-[8px] flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
              transactionType === item
                ? "border-b-2 border-primary bg-gray-100 rounded-lg border-b-[#18181B]"
                : ""
            }`}
            onClick={() => setTransactionType(item)}
          >
            {item}
          </Box>
        ))}
      </Box>

      <CommonTable headers={headers} renderRow={renderRow} data={tableData} />
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default Webhooks;
