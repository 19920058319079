import React, { memo, useCallback, useEffect, useState } from "react";
import TransferWalletBalanceSchema from "../../formSchema/TransferWalletBalance.js";
import { CloseOutlined } from "@mui/icons-material/index.js";
import { Divider, Modal, TextField } from "@mui/material";
import CommonDropDown from "../common/CommonDropDown.js";
import CommonButton from "../common/CommonButton.js";
import { Form, Field } from "react-final-form";
import apiRequest from "../../utils/api.js";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setListCrypto } from "../../redux/actions/commonAction.js";

const validateForm = (values) => {
  try {
    const schema = Yup.object().shape(
      TransferWalletBalanceSchema.reduce(
        (acc, curr) => ({ ...acc, [curr?.name]: curr?.validation }),
        {}
      )
    );
    schema.validateSync(values, { abortEarly: false });
    return {};
  } catch (err) {
    return err.inner.reduce(
      (acc, curr) => ({ ...acc, [curr?.path]: curr?.message }),
      {}
    );
  }
};

const TransferWalletBalance = (props) => {
  const { open, close, blockchain, network, applicationId, fromWalletAddress, transfersWalletList } = props;
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const listCrypto = useSelector(
    (state) => state?.common?.listCrypto?.listCrypto
  );
  const onSubmit = async (values, { reset }) => {
    try {
      const body = {
        blockchain,
        network,
        cryptoSymbol: values?.crypto,
        toAddress: values?.['wallet address'],
        fromAddress: fromWalletAddress,
        cryptoAmount: +values?.amount,
        applicationId,
      };
      const response = await apiRequest("/send-transaction", "POST", {
        ...body,
      });
      if (!response?.success) throw new Error(response?.error);
      reset();
      close();
    } catch (error) {
      console.error("Error in  transfer wallet balance", error?.message);
      setErrorMessage(error?.message);
    }
  };
  const fieldOnChange = (form, { name, value }) => {
    setErrorMessage('');
    if (name === "amount" && /^0{2,}/.test(value)) return;
    form.change(name, value);
  }

  const fetchCryptoList = useCallback(async () => {
    try {
      const body = { blockchain, network };
      const res = await apiRequest("/list-crypto", "POST", body);
      if (!res?.success) throw new Error(JSON.stringify(res?.error));
      const result = (res?.data?.CryptoSettingList || []).map(crypto => crypto?.symbol);
      dispatch(setListCrypto(result));
    } catch (error) {
      console.error("Error fetching crypto options: ", error);
    }
  }, [dispatch, network, blockchain]);

  useEffect(() => {
    fetchCryptoList();
  }, [fetchCryptoList]);

  return (
    <Modal
      open={open}
      className="flex justify-center items-center"
    >
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={TransferWalletBalanceSchema.reduce(
            (acc, curr) => ({ ...acc, [curr?.name]: curr?.value }),
            {}
          )}
          validate={validateForm}
          render={({
            handleSubmit,
            form,
            submitting,
            invalid,
            pristine,
            values,
            errors,
            touched,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col bg-white h-max rounded-xl add-configuration"
              >
                <div className="p-4 px-8">
                  <div className="flex flex-col gap-10">
                    <div className="flex justify-between">
                      <p className="text-2xl text-zinc-900 font-semibold capitalize">
                        Transfer wallet balance
                      </p>
                      <CloseOutlined onClick={close} className="cursor-pointer" />
                    </div>
                    <div className="flex flex-col gap-8 overflow-y-auto max-h-96 scroll-hidden">
                      <Field name="wallet address">
                        {({ input, meta }) => {
                          return (
                            <div className="flex justify-between items-center">
                              <p className="font-normal text-base text-zinc-800 flex items-center capitalize mr-4">
                                wallet Address:
                              </p>
                              <CommonDropDown
                                placeHolder="--- Select Address ---"
                                required={true}
                                options={transfersWalletList}
                                name="wallet address"
                                value={input?.value}
                                onChange={(event) => {
                                  fieldOnChange(form, event?.target);
                                }}
                                onBlur={input?.onBlur}
                                touched={meta?.touched}
                                error={meta?.error}
                              />
                            </div>
                          );
                        }}
                      </Field>
                      <Field name="crypto">
                        {({ input, meta }) => {
                          return (
                            <div className="flex justify-between items-center">
                              <p className="font-normal text-base text-zinc-800 flex items-center capitalize mr-4">
                                crypto :
                              </p>
                              <CommonDropDown
                                required={true}
                                options={listCrypto?.map(symbol => ({
                                  value: symbol,
                                  label: symbol
                                }))}
                                name="crypto"
                                value={input?.value}
                                onChange={(event) => {
                                  fieldOnChange(form, event?.target);
                                }}
                                onBlur={input?.onBlur}
                                touched={meta?.touched}
                                error={meta?.error}
                                placeHolder="Select Crypto"
                              />
                            </div>
                          );
                        }}
                      </Field>
                      <Field name="amount">
                        {({ input, meta }) => {
                          return (
                            <div className="flex justify-between items-center">
                              <p className="font-normal text-base text-zinc-800 flex items-center capitalize mr-4">
                                amount :
                              </p>
                              <TextField
                                className="text-field"
                                type="number"
                                name="amount"
                                variant="outlined"
                                size="small"
                                value={input?.value}
                                onChange={(event) => {
                                  fieldOnChange(form, event?.target);
                                }}
                                onBlur={input?.onBlur}
                                touched={meta?.touched ? '1' : undefined}
                                error={meta.touched && !!meta.error}
                                helperText={
                                  meta.touched && meta.error ? meta.error : ""
                                }
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <Divider />
                  {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
                  <div className="flex justify-end px-8 pb-4">
                    <CommonButton
                      disabled={submitting || invalid}
                      text="Send"
                      bgColor="black"
                      width="w-36"
                      borderRadius="rounded-3xl"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            );
          }}
        ></Form>
      </>
    </Modal>
  );
};

export default memo(TransferWalletBalance);
