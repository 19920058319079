import React, { useState, useEffect } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../../CommonTable.js";
import useDebounce from "../../../../../../customHook/useDebounce.js";
import apiRequest from "../../../../../../utils/api.js";
import { useSelector } from "react-redux";
import { getCryptoImage } from "../../../../../../utils/common.js";
import { checkViewPermission } from "../../../../../../utils/common.js";

const ThresholdApprovalTabs = (props) => {
  const isViewPermissionValid = checkViewPermission();
  const { transactionType, data, getThresholdSettings } = props;
  const [feeProfileType, setFeeProfileType] = useState("low");
  const [thresholdData, setThresholdData] = useState([]);
  const [openToast, setOpenToast] = useState("");

  const application = useSelector(
    (state) => state.globalSettings.application?.data
  );
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const type = [
    { label: "Low", key: "low" },
    { label: "Medium", key: "medium" },
    { label: "High", key: "high" },
  ];
  const handlefeeProfileType = (item) => {
    setFeeProfileType(item.key);
  };

  const headers = [
    { label: "Currency" },
    { label: "Min. Thresholds", align: "right" },
    { label: "Max. Thresholds", align: "right" },
  ];

  useEffect(() => {
    let tableData;
    switch (transactionType) {
      case "Buy":
        tableData = data?.buy;
        break;
      case "Exchange":
        tableData = data?.exchange;
        break;
      case "Receive":
        tableData = data?.receive;
        break;
      case "Sell":
        tableData = data?.sell;
        break;
      case "Send":
        tableData = data?.send;
        break;
      default:
        tableData = [];
    }
    tableData = tableData?.[feeProfileType] || [];
    setThresholdData(tableData);
  }, [transactionType, feeProfileType, data]);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const updateThreshold = async (row, value, field) => {
    let profile;
    if (feeProfileType === "low") {
      profile = "Low";
    } else if (feeProfileType === "medium") {
      profile = "Medium";
    } else {
      profile = "High";
    }
    let updateObj = {
      minAmount: 0,
      maxAmount: 0,
    };

    if (field === "maxAmount") {
      updateObj.minAmount = row?.minAmount || 0;
      updateObj.maxAmount = value;
    } else {
      updateObj.minAmount = value;
      updateObj.maxAmount = row?.maxAmount || 0;
    }

    const res = await apiRequest(
      "/create-or-update-cypto-threshold-setting",
      "POST",
      {
        applicationId: application?._id,
        profile,
        transactionType: transactionType,
        crypto: row?.crypto || row?.symbol,
        ...updateObj,
      }
    );

    if (res?.success) {
      console.log("success");
      getThresholdSettings();
      setOpenToast("Threshold setting updated successfully");
    } else {
      setOpenToast(res?.error);
    }
  };

  const debouncedUpdateThreshold = useDebounce((row, value, field) => {
    updateThreshold(row, value, field);
  }, 500);

  const handleThresholdChange = async (e, row, field) => {
    const updatedThresholdData = thresholdData.map((item) =>
      item._id === row._id ? { ...item, [field]: e.target.value } : item
    );
    setThresholdData(updatedThresholdData);
    debouncedUpdateThreshold(row, e.target.value, field);
  };

  const renderRow = (row) => {
    return (
      <>
        <TableCell component="th" scope="row" className="p-2">
          <Box className="flex items-center gap-2">
            {getCryptoImage(row?.crypto, imageBaseURL)}
            <p className="text-gray-900">
              {row?.crypto ? row?.crypto : ""}
            </p>
          </Box>
        </TableCell>
        <TableCell align="left" className="p-2">
          <Box className="flex justify-center">
            <input
              type="number"
              className="border flex text-center border-y-2 rounded py-1 w-16 h-12 appearance-none"
              value={row?.minAmount || ""}
              placeholder="0"
              onChange={isViewPermissionValid? undefined: (e) => handleThresholdChange(e, row, "minAmount")}
              disabled={isViewPermissionValid}
            />
          </Box>
        </TableCell>
        <TableCell align="left" className="p-2">
          <Box className="flex justify-center">
            <input
              type="number"
              className="border flex text-center border-y-2 rounded py-1 w-16 h-12 appearance-none"
              value={row?.maxAmount || ""}
              placeholder="0"
              onChange={isViewPermissionValid? undefined: (e) => handleThresholdChange(e, row, "maxAmount")}
              disabled={isViewPermissionValid}
            />
          </Box>
        </TableCell>
      </>
    );
  };
  
  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-2 ml-6 mb-6">
          {type.map((item) => (
            <Box
              key={item.key}
              className={`p-2 flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
                feeProfileType === item.key
                  ? "border-b-2 border-primary bg-gray-100 rounded-lg border-b-[#18181B]"
                  : ""
              }`}
              onClick={() => handlefeeProfileType(item)}
            >
              {item.label}
            </Box>
          ))}
        </Box>
        <Box>{/* <Button>Button</Button> */}</Box>
      </Box>
      <CommonTable
        headers={headers}
        renderRow={renderRow}
        data={thresholdData}
      />
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default ThresholdApprovalTabs;
