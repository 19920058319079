const transactionCategories = ["Buy", "Sell", "Receive", "Send", "Exchange"];
const transactionType = {
  buy: "Buy",
  sell: "Sell",
  receive: "Receive",
  send: "Send",
  exchange: "Exchange",
  sweepTransfer: "Sweep Transfer",
}

const walletBlockchain = [
  { blockchain: "All", key: "All" },
  { blockchain: "Bitcoin", key: "Bitcoin" },
  { blockchain: "Ethereum", key: "Ethereum" },
  { blockchain: "Litecoin", key: "Litecoin" },
  { blockchain: "Ripple", key: "Ripple" },
  { blockchain: "Tron", key: "Tron" },
];

const walletCrypto = [
  { crypto: "All", key: "All" },
  { crypto: "ETH", key: "ETH" },
  { crypto: "LTC", key: "LTC" },
  { crypto: "XRP", key: "XRP" },
  { crypto: "TRX", key: "TRX" },
  { crypto: "DAI", key: "DAI" },
  { crypto: "USDT", key: "USDT" },
  { crypto: "USDC", key: "USDC" },
  { crypto: "BTC", key: "BTC" },
];

const excludedCrypto = ["TRX", "TRC20-USDT"];

const constant = { transactionCategories, walletBlockchain, walletCrypto, transactionType, excludedCrypto };
export default constant;
