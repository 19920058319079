import { Divider } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const TransactionFilterDrawer = (props) => {
    const { isOpen, onClose, children } = props;
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

    return (
        <div
            className={`z-[1300] fixed top-0 left-0 w-full h-full inset-0 transition-opacity duration-300 ${isOpen ? "opacity-100 backdrop-blur-[1px]" : "opacity-0 pointer-events-none"}`}
        >
            <div
                className={`absolute bg-white w-full h-[37%] bottom-0 right-0 p-4 border rounded-t-md transition-transform duration-300 ${isOpen ? "translate-y-0" : "translate-y-full"}`}
                tabIndex="-1"
            >
                <div className="absolute flex gap-4 mb-8 ml-20">
                    <img
                        src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                        alt="Close"
                        onClick={onClose}
                        className="w-6 h-6 cursor-pointer hover:bg-gray-200"
                    />
                    <p className="text-black font-semibold text-lg" >Filters</p>
                </div>
                <Divider className="custom-divider"></Divider>
                <div className="mt-0 ml-20 h-[90%]">{children}</div>
            </div>
        </div>
    );
};

export default memo(TransactionFilterDrawer);
