import React, { memo } from "react";
import { Box, Select, FormControl } from "@mui/material";
import "../common/styles/filterDropdown.css"

const FilterDropdown = (props) => {
  const { label, value, name, children, handleInputChange, isMultiple, placeholder, containerClass = "",
    menuClass = "",
    labelClass = "",
    placeholderClass = "",
    useDisplayValue = false,
  } = props;

  const handleDisplayValue = () => {
    if (useDisplayValue) {
      if (!value.length) return placeholder;
      if (isMultiple && value.length > 1) {
        return <span className="selected-count">{`${value.length} selected`}</span>;
      }
      else {
        if (name === 'applicationId' && props.allApplications) {
          const selectedApplication = props.allApplications.find(app => app._id === value);
          return selectedApplication ? <span className="selected-count">{selectedApplication.name}</span> : value;
        }
        return <span className="selected-count">{value}</span>;
      }
    }
    return null;
  };

  return (
    <Box className={`filter-dropdown ${containerClass}`}>
      <p className={`filter-label ${labelClass}`}>{label}</p>
      <FormControl fullWidth size="small">
        <Select
          id="demo-simple-select"
          name={name}
          multiple={isMultiple}
          value={value}
          onChange={handleInputChange}
          MenuProps={{
            PaperProps: {
              className: `filter-menu-paper ${menuClass}`,
            }
          }}
          renderValue={useDisplayValue ? handleDisplayValue : undefined}
        >
          {children}
        </Select>
        {
          !value.length && (
            <div className={`filter-placeholder ${placeholderClass}`}>
              {placeholder}
            </div >
          )}
      </FormControl >
    </Box >
  );
};

export default memo(FilterDropdown);